<template>    
  <div class='flex flex-col lg:flex-row justify-center lg:justify-start gap-y-4 lg:gap-y-0 lg:gap-x-8'>
    <div class='lg:mt-4 w-full lg:w-1/4 lg:flex-shrink-0 flex flex-col justify-between'>
      <h1 class='font-semibold uppercase text-2xl lg:text-3xl'>
        {{ myPageMySchedulesTitle }}
      </h1>
    </div>
    <div class='rounded-lg flex-grow'>
      <div v-if='hasMultipleDays'
        class='flex flex-row justify-start items-center mt-4 border-b'>
        <div v-for='(day) in daysWithSchedules'
          :key='`day-tab-${day}`'
          class='py-2 lg:py-3 px-2 lg:px-8 text-sm lg:text-base border-b-4 border-transparent uppercase cursor-pointer hover:bg-gray-100'
          :class='selectedDayStyle(day)'
          @click='selectedDay = day'>
          {{ day }}
        </div>
      </div>
      <speaker-sessions-by-role 
        :sessions='filteredMySchedules' 
        class='' />
      <div v-if='myPageMySchedules.length === 0'
        class='my-2 py-16 bg-gray-100 rounded-md text-gray-600 text-center'>
        No Schedules
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters }   from 'vuex'
import SpeakerSessionsByRole from '@/components/speakers/SpeakerSessionsByRole.vue'
import dayjs from 'dayjs'
import sortedUniq from 'lodash/sortedUniq'

export default {
  name: 'MyPageMySchedule',
  components: {
    SpeakerSessionsByRole,
  },
  data () {
    return {
      selectedDay: '',
    }
  },
  watch: {
    hasMultipleDays: {
      handler (newVal) {
        if (newVal) {
          this.selectedDay = this.daysWithSchedules[0]
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('events',[
      'showingEventMyPageConfigurations',
    ]),
    ...mapGetters('myPages', [
      'myPageMySchedules',
    ]),
    myPageMySchedulesConfigs () {
      return this.showingEventMyPageConfigurations && this.showingEventMyPageConfigurations.myPageMySchedulesConfigs ? this.showingEventMyPageConfigurations.myPageMySchedulesConfigs : null
    },
    myPageMySchedulesTitle () {
      return this.myPageMySchedulesConfigs && this.myPageMySchedulesConfigs.title ? this.myPageMySchedulesConfigs.title : 'My Schedules'
    },
    daysWithSchedules () {
      return sortedUniq(this.myPageMySchedules.map(session => dayjs(session.sessionScheduledStartTime).format('MM.DD (ddd)')))
    },
    hasMultipleDays () {
      return this.daysWithSchedules.length > 1
    },
    filteredMySchedules () {
      return (this.hasMultipleDays)
        ? this.myPageMySchedules.filter(session => dayjs(session.sessionScheduledStartTime).format('MM.DD (ddd)') === this.selectedDay) 
        : this.myPageMySchedules
    },
  },
  methods: {
    selectedDayStyle (day) {
      return (day === this.selectedDay) ? 'selected-day-style' : ''
    },
  },
}
</script>

<style lang='scss' scoped>
  .selected-day-style {
    @apply font-semibold;
    border-bottom-color: var(--eventMainColor);
  }
</style>
