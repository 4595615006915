<template>
  <div>
    <div v-for='(session, index) in sessions'
      :key='`speaker-session-details-${index}`'
      class='standard-transition flex flex-row justify-start items-start gap-x-8 bg-gray-50 px-2 py-4 mb-4 rounded text-sm hover:shadow-md cursor-pointer hover:rounded-md'
      @click='openProgramSessionDetailsModal(session.sessionId)'>
      <div class='w-1/4 flex-grow-0 flex-shrink-0 text-xs lg:text-sm'>
        <div :style='themeTextColorStyle'>{{sessionDateString(session)}}</div>
        <div>{{sessionScheduledTimeString(session)}}</div>
        <div class='uppercase'>{{sessionLocationString(session)}}</div>
      </div>
      <div>
        <div class='font-semibold mb-1'>
          {{session.sessionTitle}}
          <span class='ml-1 bg-gray-200 font-normal uppercase p-1 rounded-md text-xs' v-if='session.role'>
            {{ session.role }}
          </span>
        </div>
        <div class='text-gray-600'>{{session.topic}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
var weekday = require('dayjs/plugin/weekday')
dayjs.extend(weekday)

export default {
  name: 'SpeakerSessionsByRole',
  props: [
    'sessions',
  ],
  computed: {
    ...mapGetters('events', [
      'themeTextColorStyle',
    ]),
  },  
  methods: {
    ...mapActions([
      'openProgramSessionDetailsModal',
    ]),
    sessionLocationString (session) {
      return session.sessionLocations.map(location => location.split('||')[0]).join(', ')
    },
    sessionDateString (session) {
      return `${dayjs(session.sessionScheduledStartTime).format('YYYY.MM.DD (ddd)')}`
    },
    sessionScheduledTimeString (session) {
      return `${dayjs(session.sessionScheduledStartTime).format('HH:mm')} - ${dayjs(session.sessionScheduledEndTime).format('HH:mm')}`
    },
  },
}
</script>
